import React from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import "./Contact.css"
import { useForm } from 'react-hook-form'
import emailjs from '@emailjs/browser'
import { message } from 'antd'

export default function ContactUs() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm()
    const formSubmit = async (data) => {
        message.loading("Please Wait...")
        await emailjs.send(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, data, process.env.REACT_APP_EMAIL_JS_PUBLIC_ID)
            .then((response) => {
                reset();
                message.success("Mail Sent Successfully. Our team will reach you shortly.")
            }, (err) => {
                message.error("Try Again Later!!!")
                reset();
            });
    }

    return (
        <>
            <section data-section className="about-us mt-4" id='contact'>
                <Card className='border-0 contactDiv'>
                    <Card.Title className='title'> Contact Us </Card.Title>
                    <Card.Body>
                        <Row className='w-100 justify-content-center'>
                            <Col md={7}>
                                <Form onSubmit={handleSubmit(formSubmit)}>
                                    <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Control type="text" placeholder="Name" style={{ height: '50px' }} {...register("user_name", { required: "This name field is required" })} />
                                        {errors.user_name && (<p className='w-100 text-center text-red-700'>{errors.user_name.message}</p>)}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Email" style={{ height: '50px' }} {...register("user_email", { required: "This email field is required" })} />
                                        {errors.user_email && (<p className='w-100 text-center text-red-700'>{errors.user_email.message}</p>)}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicSubject">
                                        <Form.Control type="text" placeholder="Subject" style={{ height: '50px' }} {...register("user_subject", { required: "This subject field is required" })} />
                                        {errors.user_subject && (<p className='w-100 text-center text-red-700'>{errors.user_subject.message}</p>)}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control className="mb-3"
                                            as="textarea"
                                            placeholder="Message"
                                            style={{ height: '200px' }} {...register("message", { required: "This message field is required" })}
                                        />
                                        {errors.message && (<p className='w-100 text-center text-red-700'>{errors.message.message}</p>)}
                                    </Form.Group>
                                    <Button className='w-100' variant="primary" type="submit" style={{ backgroundColor: '#7714C2' }}>
                                        Submit
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </section>
        </>
    )
}
