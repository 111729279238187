import React from 'react'
import './Service.css'
import { Col, Row } from 'react-bootstrap'
import Cards from './Card'
import { servicecardData } from '../../data/services'


export default function Service() {
    return (
        <>
            <section data-section className="service mt-4" id='service'>
                <Row className='gap-4'>
                    <Col className="heading" md={12}>
                        <p className="title">Services</p>
                    </Col>
                    <Col className="sub-title" md={12}>
                        <p className="sub-title-content mb-0 mt-12"><span> What </span> we do?</p>
                        <div className="line1"></div>
                    </Col>
                    <div className='flex flex-wrap justify-between items-center gap-y-24'>
                        {
                            servicecardData.map((data, index) => {
                                return (
                                    <Cards key={index} image={data.image} heading={data.heading} description={data.description} cardNo = {index} />
                                )
                            })
                        }
                    </div>
                </Row>
            </section>
        </>
    )
}
