import React from "react";
import "./Navbar.css";
import navlogo from "../../assets/images/Transparent 2.png";
import { useState, useEffect, useRef } from "react";
import menu from "../../assets/images/Menu.png"

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const [textcolorChange, setTextColorChange] = useState(false);
  const changeNavbarColor = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= 80) {
      setColorchange(true);
      setTextColorChange(true);
    } else {
      setColorchange(false);
      setTextColorChange(false);
    }
  };


  const handleClickScroll = (elemId) => {
    const element = document.getElementById(`${elemId}`);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [activeSection, setActiveSection] = useState(null);
  const sections = useRef([]);

  const handleScroll = () => {
    const pageYOffset = window.pageYOffset;
    let newActiveSection = null;

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (pageYOffset >= sectionOffsetTop && pageYOffset
        < sectionOffsetTop + sectionHeight) {
        newActiveSection = section.id;
      }
    });

    setActiveSection(newActiveSection);
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [mobileLinks, setMobileLinks] = useState('none')

  const toggleMobileLinks = () => {
    if (mobileLinks !== 'block') {
      setMobileLinks('block')
    }
    else {
      setMobileLinks('none')
    }
  }

  useEffect(() => {
    sections.current = document.querySelectorAll('[data-section]');
    window.addEventListener('scroll', handleScroll);

    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  window.addEventListener("scroll", changeNavbarColor);
  return (
    <div className={colorChange ? 'navbar colorChange' : 'navbar nocolorChange'}>
      <div className="h-[70px] w-[100%] max-w-[1400px] flex justify-between items-center px-3">
        <div>
          <img src={navlogo} className="navLogo" alt="navLogo" />
        </div>

        {/* <div>
          <ul className="min-h-[70px] flex items-center gap-10 pt-4 mr-8 navDiv">
            <li className={textcolorChange ? activeSection === 'home' ? "activeScroll navlist colorChange" : 'navlist colorChange' : "navlist nocolorChange"} onClick={() => { handleClickScroll('home') }}>
              Home
              <div className="underDiv"></div>
            </li>
            <li className={textcolorChange ? activeSection === 'about' ? "activeScroll navlist colorChange" : 'navlist colorChange' : "navlist nocolorChange"} onClick={() => { handleClickScroll('about') }} >
              About Us
              <div className="underDiv"></div>
            </li>
            <li className={textcolorChange ? activeSection === 'service' ? "activeScroll navlist colorChange" : 'navlist colorChange' : "navlist nocolorChange"} onClick={() => { handleClickScroll('service') }}>
              Service
              <div className="underDiv"></div>
            </li>
            <li className={textcolorChange ? activeSection === 'domain' ? "activeScroll navlist colorChange" : 'navlist colorChange' : "navlist nocolorChange"} onClick={() => { handleClickScroll('domain') }}>
              Work
              <div className="underDiv"></div>
            </li>
            <li className={textcolorChange ? activeSection === 'contact' ? "activeScroll navlist colorChange" : 'navlist colorChange' : "navlist nocolorChange"} onClick={() => { handleClickScroll('contact') }}>
              Contact Us
              <div className="underDiv"></div>
            </li>
          </ul>
        </div> */}

        {
          windowSize[0] > "1100" ? (<div>
            <ul className="min-h-[70px] flex items-center gap-10 pt-4 mr-8 navDiv">
              <li className={textcolorChange ? activeSection === 'home' ? "activeScroll navlist colorChange" : 'navlist colorChange' : "navlist nocolorChange"} onClick={() => { handleClickScroll('home') }}>
                Home
                <div className="underDiv"></div>
              </li>
              <li className={textcolorChange ? activeSection === 'about' ? "activeScroll navlist colorChange" : 'navlist colorChange' : "navlist nocolorChange"} onClick={() => { handleClickScroll('about') }} >
                About Us
                <div className="underDiv"></div>
              </li>
              <li className={textcolorChange ? activeSection === 'service' ? "activeScroll navlist colorChange" : 'navlist colorChange' : "navlist nocolorChange"} onClick={() => { handleClickScroll('service') }}>
                Service
                <div className="underDiv"></div>
              </li>
              <li className={textcolorChange ? activeSection === 'domain' ? "activeScroll navlist colorChange" : 'navlist colorChange' : "navlist nocolorChange"} onClick={() => { handleClickScroll('domain') }}>
                Work
                <div className="underDiv"></div>
              </li>
              <li className={textcolorChange ? activeSection === 'contact' ? "activeScroll navlist colorChange" : 'navlist colorChange' : "navlist nocolorChange"} onClick={() => { handleClickScroll('contact') }}>
                Contact Us
                <div className="underDiv"></div>
              </li>
            </ul>
          </div>) : (
            <img src={menu} onClick={toggleMobileLinks} alt="menu" />
          )
        }

        {/* <p>{windowSize[0]}</p> */}
      </div>

      {/* Mobile View Link */}

      <div className="w-[100%] h-[fit-content] bg-white " style={{ display: mobileLinks }} >
        <ul className="min-h-[70px] flex flex-col items-center gap-10 pt-4 mr-8" >
          <li className="text-black" onClick={() => { handleClickScroll('home') }}>
            Home
            <div className="underDiv"></div>
          </li>
          <li className="text-black" onClick={() => { handleClickScroll('about') }} >
            About Us
            <div className="underDiv"></div>
          </li>
          <li className="text-black" onClick={() => { handleClickScroll('service') }}>
            Service
            <div className="underDiv"></div>
          </li>
          <li className="text-black" onClick={() => { handleClickScroll('domain') }}>
            Work
            <div className="underDiv"></div>
          </li>
          <li className="text-black" onClick={() => { handleClickScroll('contact') }}>
            Contact Us
            <div className="underDiv"></div>
          </li>
        </ul>
      </div>


    </div>

  );
};

export default Navbar;
