import React from 'react'
import footerlogo from "../../assets/images/Logo.png"
import './Footer.css'

export default function Footer() {
    return (
        <div className="w-full min-h-[200px] bg-[#7714C21A] flex flex-col items-center justify-center">
            <div className="footer_first d-flex w-[94%] justify-between">
                <div className="footer_contact contact">
                    <p className='heading'>Contact</p>
                    <p className='flex items-center justify-center text-center gap-1'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 5H7.5C4.73858 5 2.5 7.23858 2.5 10V21.25C2.5 24.0114 4.73858 26.25 7.5 26.25H22.5C25.2614 26.25 27.5 24.0114 27.5 21.25V10C27.5 7.23858 25.2614 5 22.5 5ZM7.5 6.9875H22.5C23.8322 6.99004 25.0031 7.87079 25.375 9.15L15.95 16.1375C15.6917 16.3901 15.3415 16.5262 14.9804 16.5144C14.6194 16.5026 14.2788 16.3439 14.0375 16.075L4.65 9.1625C5.01469 7.88716 6.17363 7.00271 7.5 6.9875ZM4.4875 21.25C4.4875 22.9138 5.83624 24.2625 7.5 24.2625H22.5C24.1589 24.2556 25.5 22.9089 25.5 21.25V11.2125L17 17.4625C16.4568 17.9682 15.7422 18.2496 15 18.25C14.2284 18.2377 13.4889 17.9392 12.925 17.4125L4.4875 11.1625V21.25Z" fill="#7714C2" />
                        </svg>
                        <span>
                            recruitment@nexolvetech.com
                        </span>
                    </p>
                </div>
                <div className="footer_contact">
                    <img src={footerlogo} alt="" />
                </div>
            </div>
            <div className='w-[94%] copyright'>
                <p className="font-normal text-sm tracking-[5%] text-black opacity-50 mb-0 mt-2">Copyright © {new Date().getFullYear()} NexolveTech. All rights reserved</p>
            </div>
        </div>
    )
}