import React from 'react'
import Card from './Card'
import { cardData } from '../../data/card_data'

const Domain = () => {
    return (

        <div data-section className='w-[90%] flex flex-col mx-auto gap-14 py-4 text-center' id='domain'>
            <h3 className='text-[#7714C2] text-4xl mt-20 font-bold'>Domains</h3>
            <div className='flex flex-wrap justify-between items-center gap-y-3'>
                {
                    cardData.map((data) => {
                        return (
                            <Card bg={data.bgImg} heading={data.heading} icon={data.icon} />
                        )
                    })
                }
            </div>
        </div>

    )
}

export default Domain;
