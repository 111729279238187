import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Service from './components/service/Service';
import AboutUs from './components/aboutUs/AboutUs';
import Domain from './components/domain/Domain';
import ContactUs from './components/contactUs/ContactUs';
import Nexolve from './components/nexolve/Nexolve';
import OurApproach from './components/ourApproach/OurApproach';


function App() {
  return (
    <div className="overflow-x-hidden">
      <div className='max-w-[100%] mx-auto overflow-hidden'>
        <Header />

        <AboutUs />

        <Service />

        <Domain />

        <OurApproach />

        <Nexolve />

        <ContactUs />

        <Footer />
      </div>
    </div>
  );
}

export default App;
