import React from 'react'
import serviceFrame from "../../assets/images/Rectangle 13.png"
import "./Card.css"

const Cards = ({ image, heading, description, cardNo }) => {
    let backgroundColor = '#fff';
    let direction = 'row'
    if (cardNo === 1) {
        backgroundColor = '#fff'
        direction = 'row-reverse'
    }
    else {
        backgroundColor = '#7714C21A'
    }
    return (
        <div className={`w-[100%] flex flex-${direction} justify-evenly items-top flex-wrap bg-[${backgroundColor}] relative py-14 serviceCardDiv`}>
            <div className='relative imageCardDiv'>
                <img src={image} width="100%" className='z-3 relative' alt='cardimage' />
                <img src={serviceFrame} width="800px" className='absolute -top-5 z-1' alt='serviceimage' />
            </div>
            <div className='w-[45%] description'>
                <h3 className='text-[#7714C2] font-semibold'>{heading}</h3>
                <p className='text-[1.2rem] font-normal tracking-wide leading-8'>{description}</p>
            </div>
        </div>
    )
}


export default Cards