import React, { useEffect, useState } from "react";
import "./Header.css";
import Navbar from "../navbar/Navbar";
import dotImg from "../../assets/images/Group 1.png";
import MobileHeader from "./MobileHeader";


export default function Header() {

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

  }, []);

  return (
    <div data-section className="w-full min-h-[700px] heroSection-bg z-10 relative flex max-w-[100%]" id="home">
      <Navbar />

      {
        windowSize[0] > "1100" ? (
          <>
            {/* left part */}
            <div className=" min-w-[613px] h-[800px] backdrop-blur-lg bg-white/20 leftPartHero">
              <div className="h-full w-full flex flex-col pt-[30%]">
                <div className="font-extrabold text-[150px] leading-[225px] opacity-30 ">
                  <p className="text-right herosection-text">Let’s</p>
                </div>

                <div className="w-full flex flex-col items-center mt-[10%] gap-3">
                  <div>
                    <img src={dotImg} alt="" />
                  </div>
                  <p className="text-xl text-white font-medium max-w-[478px] tracking-[3%]">
                    Welcome to the new era of recruitment! As a startup we infuse the
                    industry with a burst of energy, embracing agility, and adapting
                    swiftly to changing market trends.
                  </p>
                </div>
              </div>
            </div>

            {/* right  part  */}
            <div className="h-full w-full pt-[20%]">
              <p className="text-[150px] font-extrabold leading-[225px] text-[#7714C2]">
                Evolve
              </p>
            </div>
          </>
        ) : (
          <MobileHeader />
        )
      }

      {/* <MobileHeader /> */}

      {
        windowSize[0] < "700" ? (
          <p className="text-[60px] leading-[30px] tracking-[5%] font-bold text-white absolute top-[50%] right-[-12%] -rotate-90 floatedText"><span className="font-normal"></span></p>
        ) : (
          <p className="text-[60px] leading-[30px] tracking-[5%] font-bold text-white absolute top-[50%] right-[-12%] -rotate-90 floatedText">NEXOLVE<span className="font-normal">TECH</span></p>
        )
      }
    </div>
  );
}
