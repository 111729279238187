import React from 'react'
import './Nexolve.css'
import { Col, Row } from 'react-bootstrap'
import "./Card.css"
import { whynexsolvecardData } from '../../data/whynexolve'

export const Card = ({ bg, heading }) => {
    return (
        <div className='relative border w-[90%] p-0 m-0 h-[180px] bg-no-repeat flex text-center justify-center items-center cardContainer' style={{ backgroundImage: `url('${bg}')`, boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.25)"  }}>
            <div className='text-white text-center flex flex-col items-center z-20 gap-2'>
                <p className=''>{heading}</p>
            </div>
        </div>
    )
}


export default function Nexolve() {
    return (
        <>
            <section className="nexolve">
                <Row>
                    <Col className="heading" md={12}>
                        <p className="title">Why NexolveTech</p>
                    </Col>
                    <Col className='body'>

                        <div className='w-[90%] flex flex-col mx-auto gap-3 py-5 text-center'>
                            <Row className='flex flex-wrap justify-center items-stretch gap-y-5 card-section'>
                                {
                                    whynexsolvecardData.map((data) => {
                                        return (
                                            <Col className='only-card' md={4}>
                                                <Card bg={data.bgImg} heading={data.heading} />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    )
}
