import React from 'react';
import "./Card.css"



const Card = ({ bg, heading, icon }) => {
    return (
        <div className='relative border w-[49%] p-0 m-0 h-[250px] bg-no-repeat flex text-center justify-center items-center cardContainer' style={{ backgroundImage: `url('${bg}')`, boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.25)" }}>
            <div className='w-[100%] h-[100%] bg-[#ffffffa6] absolute z-10 overlayer'></div>
            <div className='text-white text-center flex flex-col items-center z-20 gap-2'>
                <img src={icon} width="25%" alt='icon' />
                <p className=''>{heading}</p>
            </div>
        </div>
    )
}

export default Card
