import Service1 from '../assets/images/Services 1.png'
import Service2 from '../assets/images/Services 2.png'
import Service3 from '../assets/images/Services 3.png'

export const servicecardData = [
    {
        image: Service1,
        heading: "Permanent & Contract Staffing",
        description: "Discover top talent for your organization through our comprehensive Permanent and Contract Staffing solutions. Our expert team sources and places skilled professionals seamlessly, ensuring a perfect fit for your company culture and needs. Elevate your workforce with our reliable and flexible staffing services.",
        backgroundcolor: "#7714c21a"
    },

    {
        image: Service2,
        heading: "Human Resources Outsourcing",
        description: "Optimize your business by entrusting your Human Resources functions to our dedicated experts through our Human Resources Outsourcing (HRO) services. From payroll and benefits administration to compliance and employee lifecycle management, we ensure streamlined and efficient HR operations, allowing you to focus on what truly matters – growing your business",
        backgroundcolor: "#fff"
    },

    {
        image: Service3,
        heading: "Executive Hiring",
        description: "Elevate your leadership team with our specialized Executive Hiring services. Our rigorous and tailored approach identifies exceptional C-suite and senior-level candidates who align with your company's vision and drive success. Trust us to source, assess, and secure top-tier executives who will shape the future of your organization.",
        backgroundcolor: "#7714c21a"
    }
]