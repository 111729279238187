import React from 'react'
import ourApproach from "../../assets/images/Our Approach.png"
import ourApproachOne from "../../assets/images/Our Approach(1).png"

const OurApproach = () => {
  return (
    <div className='mt-5'>
      <img src={ourApproach} alt="" className='hidden md:block w-[100%]' />
      <img src={ourApproachOne} alt="" className='block md:hidden w-[100%]' />
    </div>
  )
}

export default OurApproach