import React from 'react'

const MobileHeader = () => {
    return (
        <div className='headerDiv'>
            <div className='font-extrabold text-[5rem] text-white flex flex-col justify-center items-left absolute bottom-[5%] left-[5%] m-0 p-0'>
                <span>Let's</span>
                <span>Evolve</span>
            </div>
        </div>
    )
}

export default MobileHeader
