import Rectangle15 from "../assets/images/Rectangle 15.jpeg"
import Architect from "../assets/images/Architect.png"

import Rectangle16 from "../assets/images/Rectangle 16.jpeg"
import WebApps from "../assets/images/Web Apps.png"


import Rectangle17 from "../assets/images/Rectangle 17.jpeg"
import Rectangle18 from "../assets/images/Rectangle 18.jpeg"
import Rectangle19 from "../assets/images/Rectangle 19.jpeg"
import Rectangle20 from "../assets/images/Rectangle 20.jpeg"
import Rectangle21 from "../assets/images/Rectangle 21.jpeg"
import Rectangle22 from "../assets/images/Rectangle 22.jpeg"
import Rectangle23 from "../assets/images/Rectangle 23.jpeg"
import Rectangle24 from "../assets/images/Rectangle 24.jpeg"



import Brainstrom from "../assets/images/Brainstorm Skill.png"
import Chiropractic from "../assets/images/Chiropractic Caduceus .png"
import Broker from "../assets/images/Broker.png"
import Protect from "../assets/images/Protect.png"
import VideoCamera from "../assets/images/Video Camera.png"


import Gears from "../assets/images/Gears.png"
import DataBackup from "../assets/images/Data Backup.png"
import investment from "../assets/images/Investment.png"

export const cardData = [
    {
        bgImg: Rectangle15,
        icon: Architect,
        heading: "Product Engineering Staffing"
    },
    {
        bgImg: Rectangle16,
        icon: WebApps,
        heading: "Application Support & Maintenance Staffing"
    },
    {
        bgImg: Rectangle17,
        icon: DataBackup,
        heading: "Data & Analytics Talent Solutions"
    },
    {
        bgImg: Rectangle18,
        icon: investment,
        heading: "Finance and Accounting Recruitment"
    },
    {
        bgImg: Rectangle19,
        icon: Gears,
        heading: "Cognitive Automation Staffing"
    },
    {
        bgImg: Rectangle20,
        icon: Brainstrom,
        heading: "Intelligent Operations Workforce"
    },
    {
        bgImg: Rectangle21,
        icon: Chiropractic,
        heading: "Healthcare"
    },
    {
        bgImg: Rectangle22,
        icon: Broker,
        heading: "Banking Industry Staffing"
    },
    {
        bgImg: Rectangle23,
        icon: Protect,
        heading: "Insurance Sector Professionals"
    },
    {
        bgImg: Rectangle24,
        icon: VideoCamera,
        heading: "Media & Entertainment Talent Acquisition"
    },
]