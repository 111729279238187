import Rectangle23 from '../assets/images/Rectangle 23.png'
import Rectangle24 from '../assets/images/Rectangle 24.png'
import Rectangle25 from '../assets/images/Rectangle 25.png'
import Rectangle26 from '../assets/images/Rectangle 26.png'
import Rectangle27 from '../assets/images/Rectangle 27.png'
import Rectangle28 from '../assets/images/Rectangle 28.png'
import Rectangle29 from '../assets/images/Rectangle 29.png'


export const whynexsolvecardData = [
    {
        bgImg: Rectangle23,
        heading: "Decreased time to hire"
    },
    {
        bgImg: Rectangle24,
        heading: "Improved fill rates"
    },
    {
        bgImg: Rectangle25,
        heading: "Increased quality of human capital"
    },
    {
        bgImg: Rectangle26,
        heading: "Reduced early stage turnover"
    },
    {
        bgImg: Rectangle27,
        heading: "Minimized administrative effort"
    },
    {
        bgImg: Rectangle28,
        heading: "Compliant recruitment processes"
    },
    {
        bgImg: Rectangle29,
        heading: "Dedicated Account Manager"
    }
]