import React from 'react'
import './AboutUs.css'
import { Card, Col, Row } from 'react-bootstrap'
import aboutImage from "../../assets/images/About Image.png"


export default function AboutUs() {
    return (
        <>
            <section data-section className="about-us mt-2 py-8" id='about'>
                <Card className='border-0 aboutDiv'>
                    <Card.Title className='title mt-4'> About Us </Card.Title>
                    <Card.Body>
                        <Row>
                            <Col className="description m-0 p-0" md={6}>
                                <b className="title-text">Unlock the Power of Talent</b>
                                <br />
                                <br />
                                <p className="title-desc">
                                    We are a dynamic startup recruitment & staffing company, specializing in permanent staffing, contract staffing, and executive hiring. With our unrivalled expertise, we deliver exceptional services that not only comply with recruitment processes but also minimize administrative and onboarding efforts. Harness our industry knowledge and innovative approach to build your dream team effortlessly. Maximize your company's potential with our streamlined solutions and let us empower your success.
                                </p>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="purple-box">
                        <Row>
                            <Col md={6}>
                                <p className="purple-box-text">When you partner with us, you can trust that we follow a strict compliance framework. Our commitment extends to data privacy, anti-discrimination policies, and equal employment opportunities, ensuring a fair and inclusive recruitment experience</p>
                            </Col>
                            <Col md={6} className='d-flex position-absolute justify-content-end bottom-0 right-0 aboutImage'>
                                <img src={aboutImage} alt="" width="350" height="200" />
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </section>
        </>
    )
}
